<template>
	<v-card outlined>
		<v-card-title class="text-subtitle-2">
			{{ resource.id }}
			<v-spacer />
			<v-btn plain @click="deleteResource"
				><v-icon small>mdi-delete</v-icon></v-btn
			>
		</v-card-title>
		<v-divider />
		<v-card-text>
			<v-row
				v-for="role in resource.accessList"
				:key="role.role"
				align="center"
			>
				<v-col cols="8"> {{ role.role }} </v-col>
				<v-col cols="1">:</v-col>
				<v-col cols="3" :class="role.priviliges ? '' : 'warning'">
					{{ role.priviliges }}
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
	import { useWebAppStore } from "@/store/webapps";
	export default {
		name: "WebAppResource",
		props: {
			resource: Object,
		},
		setup() {
			const webAppStore = useWebAppStore();
			return { webAppStore };
		},
		data() {
			return {};
		},
		methods: {
			deleteResource() {
				this.webAppStore.deleteWebAppResource(this.resource.id);
			},
		},
	};
</script>

