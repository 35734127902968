/* eslint-disable */
import { defineStore } from "pinia";
import { useSessionStore } from "@/store/session";
import { db } from "@/services/firebase";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    setDoc,
    deleteDoc,
} from "firebase/firestore";

export const useWebAppStore = defineStore({
    id: "webapps",
    state: () => ({
        webAppList: [],
        webApp: {
            id: "",
            name: "",
        },
        webAppResources: [],
        webAppResource: {
            id: "",
            accessList: [
                {
                    role: "",
                    priviliges: "",
                },
            ],
        },
    }),
    actions: {
        async createWebAppResource(id) {
            try {
                await setDoc(
                    doc(db, "webapps", this.webApp.id, "resources", id),
                    {}
                );
                await this.fetchWebAppResources(this.webApp.id);
                useSessionStore().showSuccess();
            } catch (e) {
                useSessionStore().showError(e);
            }
        },
        async deleteWebAppResource(id) {
            await deleteDoc(
                doc(db, "webapps", this.webApp.id, "resources", id)
            );
            await this.fetchWebAppResources(this.webApp.id);
            useSessionStore().showSuccess();
        },
        async fetchWebApps() {
            this.webAppList = await getDocs(collection(db, "webapps"));
            this.webAppList = this.webAppList.docs.map((doc) => {
                return { ...doc.data(), id: doc.id };
            });
            this.webAppList.sort();
        },
        async fetchWebApp(appId) {
            try {
                const docSnap = await getDoc(doc(db, "webapps", appId));
                this.webApp = { ...docSnap.data() };
                this.webApp.id = docSnap.id;
            } catch (e) {
                useSessionStore().showError(e);
            }
        },
        async fetchWebAppResources() {
            this.webAppResources = await getDocs(
                collection(db, "webapps", this.webApp.id, "resources")
            );
            this.webAppResources = this.webAppResources.docs.map((doc) => {
                return { ...doc.data(), id: doc.id };
            });
            this.webAppResources.sort();
        },
    },
});
