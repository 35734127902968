<template>
	<v-sheet min-height="300" class="grey lighten-5">
		<v-toolbar flat dense>
			<v-text-field
				style="max-width: 300px"
				v-model="newResource"
				placeholder="add resource"
				dense
				flat
				solo
				append-icon="mdi-plus-circle-outline"
				hide-details
				@click:append="addResource"
			/>
			<v-divider vertical class="mx-4" />
			<v-icon @click="webAppStore.fetchWebAppResources()"
				>mdi-refresh</v-icon
			>
		</v-toolbar>
		<v-divider />
		<v-container class="grey lighten-5">
			<v-row
				><v-col
					cols="12"
					md="6"
					lg="4"
					v-for="resource in webAppStore.webAppResources"
					:key="resource.id"
				>
					<WebAppResource :resource="resource" />
				</v-col>
			</v-row>
		</v-container>
	</v-sheet>
</template>

<script>
	import { useWebAppStore } from "@/store/webapps";
	import WebAppResource from "@/views/webapp/WebAppResource";
	export default {
		name: "WebAppResources",
		components: { WebAppResource },
		data() {
			return {
				newResource: "",
			};
		},
		setup() {
			const webAppStore = useWebAppStore();
			return { webAppStore };
		},
		methods: {
			addResource() {
				this.webAppStore.createWebAppResource(this.newResource).then(() => {
					this.newResource = "";
				});
			},
		},
	};
</script>
